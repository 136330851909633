define("discourse/plugins/discourse-badge-search/discourse/components/badge-user-card", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "div",
    avatarSrc(avatarTemplate) {
      return avatarTemplate.replace("{size}", "75");
    }
  }, [["method", "avatarSrc", [(0, _decorators.default)("user.avatar_template")]]]));
});