define("discourse/plugins/discourse-badge-search/discourse/templates/components/badge-search-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.displayCount}}
    <a
      href={{this.url}}
      title={{i18n "badges.granted" count=this.displayCount}}
      class="grant-count"
    >
      {{this.displayCount}}
      <span class="space"> </span>
    </a>
  {{/if}}
  {{#if true}}
    <a href={{this.url}} class="check-display status-checked">
      {{d-icon "question"}}
    </a>
  {{/if}}
  
  {{! THIS IS WHERE TO ADD STUFF ABOUT SELECING THE ONES YOU WANT }}
  {{#if true}}
    {{#if this.badge.isSelected}}
      <DButton
        @icon="check"
        @class="favorite-btn"
        @action={{action "toggleBadgeSelect"}}
        @actionParam={{this}}
      />
    {{else}}
      <DButton
        @icon="far-circle"
        @class="favorite-btn"
        @action={{action "toggleBadgeSelect"}}
        @actionParam={{this}}
      />
    {{/if}}
  {{/if}}
  
  <div class="badge-contents">
    <a href={{this.url}} class="badge-icon {{this.badge.badgeTypeClassName}}">
      {{icon-or-image this.badge}}
    </a>
    <div class="badge-info">
      <div class="badge-info-item">
        <h3><a href={{this.url}} class="badge-link">{{this.badge.name}}</a></h3>
        <div class="badge-summary">{{html-safe this.summary}}</div>
      </div>
    </div>
  </div>
  */
  {
    "id": "lbZOnxm1",
    "block": "[[[41,[30,0,[\"displayCount\"]],[[[1,\"  \"],[10,3],[15,6,[30,0,[\"url\"]]],[15,\"title\",[28,[37,1],[\"badges.granted\"],[[\"count\"],[[30,0,[\"displayCount\"]]]]]],[14,0,\"grant-count\"],[12],[1,\"\\n    \"],[1,[30,0,[\"displayCount\"]]],[1,\"\\n    \"],[10,1],[14,0,\"space\"],[12],[1,\" \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[41,true,[[[1,\"  \"],[10,3],[15,6,[30,0,[\"url\"]]],[14,0,\"check-display status-checked\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"question\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,true,[[[41,[30,0,[\"badge\",\"isSelected\"]],[[[1,\"    \"],[8,[39,3],null,[[\"@icon\",\"@class\",\"@action\",\"@actionParam\"],[\"check\",\"favorite-btn\",[28,[37,4],[[30,0],\"toggleBadgeSelect\"],null],[30,0]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,3],null,[[\"@icon\",\"@class\",\"@action\",\"@actionParam\"],[\"far-circle\",\"favorite-btn\",[28,[37,4],[[30,0],\"toggleBadgeSelect\"],null],[30,0]]],null],[1,\"\\n\"]],[]]]],[]],null],[1,\"\\n\"],[10,0],[14,0,\"badge-contents\"],[12],[1,\"\\n  \"],[10,3],[15,6,[30,0,[\"url\"]]],[15,0,[29,[\"badge-icon \",[30,0,[\"badge\",\"badgeTypeClassName\"]]]]],[12],[1,\"\\n    \"],[1,[28,[35,5],[[30,0,[\"badge\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"badge-info\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"badge-info-item\"],[12],[1,\"\\n      \"],[10,\"h3\"],[12],[10,3],[15,6,[30,0,[\"url\"]]],[14,0,\"badge-link\"],[12],[1,[30,0,[\"badge\",\"name\"]]],[13],[13],[1,\"\\n      \"],[10,0],[14,0,\"badge-summary\"],[12],[1,[28,[35,6],[[30,0,[\"summary\"]]],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"if\",\"i18n\",\"d-icon\",\"d-button\",\"action\",\"icon-or-image\",\"html-safe\"]]",
    "moduleName": "discourse/plugins/discourse-badge-search/discourse/templates/components/badge-search-card.hbs",
    "isStrictMode": false
  });
});