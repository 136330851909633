define("discourse/plugins/discourse-badge-search/discourse/controllers/badge-users-index", ["exports", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse/models/user", "discourse/mixins/buffered-content", "@ember/controller"], function (_exports, _ajax, _decorators, _user, _bufferedContent, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend((0, _bufferedContent.bufferedProperty)("defaultMessageBody"), (0, _bufferedContent.bufferedProperty)("defaultTitle"), dt7948.p({
    defaultMessageBody: _user.default.current().default_message_body,
    // todo: set default
    defaultTitle: _user.default.current().default_title,
    // todo: set default
    queryParams: ["b"],
    badges: null,
    saved: true,
    saveDisabled(body, title) {
      window.console.log("disabled save", this, body, this.defaultMessageBody, title, this.defaultTitle);
      return this.defaultMessageBody === body && this.defaultTitle === title;
    },
    actions: {
      save() {
        window.console.log("doing the save!", this.buffered.get("defaultTitle"), this.buffered.get("defaultMessageBody"));
        this.setProperties({
          isSaving: true
          // saved: false,
        });
        window.console.log("putting now", this);
        (0, _ajax.ajax)("/badge-users.json", {
          type: "PUT",
          data: {
            b: this.b,
            default_title: this.buffered.get("defaultTitle"),
            default_message_body: this.buffered.get("defaultMessageBody")
          }
        }).then(() => {
          window.console.log("doing then");
          // this.commitBuffer();
          this.set("saved", true);
          // this.set("model.overridden", data.overridden);
        }).catch("didn't work").finally(() => this.set("isSaving", false));
        window.console.log("done with put");
      },
      pmAllUsers(users) {
        const userIds = users.map(user => user.id);
        window.console.log("PM all users!", users, userIds);
        (0, _ajax.ajax)("/badge-users-pm", {
          type: "PUT",
          data: {
            default_title: this.buffered.get("defaultTitle"),
            default_message_body: this.buffered.get("defaultMessageBody"),
            b: this.b,
            target_users: userIds
          }
        }).then(() => {
          window.console.log("doing then");
          // this.commitBuffer();
          this.set("saved", true);
          // this.set("model.overridden", data.overridden);
        }).catch("didn't work").finally(() => this.set("isSaving", false));
        window.console.log("done with put");
      }

      // reset() {
      //   window.console.log("doing the reset!");

      //   this.setProperties({
      //     isSaving: true,
      //     saved: false,
      //   });
      //   ajax("/badge-users-broken", { type: "PUT" })
      //     .then((data) => {
      //       this.buffered.set("defaultTitle", data.defaultTitle);
      //       this.buffered.set("defaultMessageBody", data.defaultMessageBody);
      //       this.commitBuffer();
      //       this.set("saved", true);
      //       this.set("model.overridden", false);
      //     })
      //     .finally(() => this.set("isSaving", false));
      // },
    }

    // @discourseComputed("badges")
    // badgesList(badges) {
    //   return "10+11";
    //   // return badges;
    // },
  }, [["method", "saveDisabled", [(0, _decorators.default)("buffered.defaultMessageBody", "buffered.defaultTitle")]]]));
});