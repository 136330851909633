define("discourse/plugins/discourse-badge-search/discourse/components/badge-search-card", ["exports", "discourse/lib/text", "@ember/component", "discourse-common/utils/decorators", "@ember/utils"], function (_exports, _text, _component, _decorators, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    size: "medium",
    classNameBindings: [":badge-card", "size", "badge.slug"],
    url(badgeUrl, filterUser, username) {
      return filterUser ? `${badgeUrl}?username=${username}` : badgeUrl;
    },
    displayCount(count, grantCount) {
      if (count == null) {
        return grantCount;
      }
      if (count > 1) {
        return count;
      }
    },
    summary(size, longDescription, description) {
      if (size === "large") {
        if (!(0, _utils.isEmpty)(longDescription)) {
          return (0, _text.emojiUnescape)((0, _text.sanitize)(longDescription));
        }
      }
      return (0, _text.sanitize)(description);
    },
    showFavorite(badgeId) {
      return ![1, 2, 3, 4].includes(badgeId);
    },
    actions: {
      toggleBadgeSelect() {
        this.badge.set("isSelected", !this.badge.get("isSelected"));
      }
    }
  }, [["method", "url", [(0, _decorators.default)("badge.url", "filterUser", "username")]], ["method", "displayCount", [(0, _decorators.default)("count", "badge.grant_count")]], ["method", "summary", [(0, _decorators.default)("size", "badge.long_description", "badge.description")]], ["method", "showFavorite", [(0, _decorators.default)("badge.id")]]]));
});