define("discourse/plugins/discourse-badge-search/discourse/routes/badge-users-index", ["exports", "discourse/routes/discourse", "discourse/plugins/discourse-badge-search/discourse/models/badge-users", "discourse/models/composer", "discourse/models/user"], function (_exports, _discourse, _badgeUsers, _composer, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model(params) {
      window.console.log("route setting params", params);
      return _badgeUsers.default.listBadgeUsers(params.b);
    },
    queryParams: {
      b: {
        replace: true
      }
    },
    setupController(controller, model) {
      const currentUser = _user.default.current();

      // window.console.log("user route up ", currentUser, controller, model);
      // window.console.log("the badge", model.badges);
      // controller.set("groups", model);
      controller.set("badges", model.badges);
      controller.set("users", model.users);
      controller.buffered.set("defaultMessageBody", this.currentUser.default_message_body || "Write your default PM text here.");
      // controller.set("defaultMessageBody", "Include contact information here.");
      controller.buffered.set("defaultTitle", this.currentUser.default_title || "default title");
      // controller.set("defaultTitle", "Potential Job Opportunity");
    },
    composePMignoredDeleteme(user) {
      const u = _user.default.find(user.id);
      // this._close();
      this.composePrivateMessage(u, null);
    },
    actions: {
      composePM(user, defaultTitle, defaultMessageBody) {
        // window.console.log("my real2 message", user);
        const u = user;
        // this._close();
        const recipients = u ? u.username : "";
        const reply = null;

        // used only once, one less dependency
        return this.controllerFor("composer").open({
          action: _composer.default.PRIVATE_MESSAGE,
          recipients,
          archetypeId: "private_message",
          draftKey: _composer.default.NEW_PRIVATE_MESSAGE_KEY,
          draftSequence: 0,
          reply,
          title: defaultTitle,
          topicBody: defaultMessageBody
        });
      }
    }
  });
});