define("discourse/plugins/discourse-badge-search/discourse/models/badge-users", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _object, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Badgeusers = _object.default.extend();
  Badgeusers.reopenClass({
    listBadgeUsers(badgeList) {
      const badgeParams = badgeList.replace(/ /g, "+");
      window.console.log("badge user list", badgeList, badgeParams);
      const bParams = (0, _ajax.ajax)(`/badge-users.json?b=${badgeParams}`, {
        type: "GET"
      }).catch(_ajaxError.popupAjaxError);
      window.console.log("listBadgeUsers returns", bParams);
      return bParams;
    }
  });
  var _default = _exports.default = Badgeusers;
});