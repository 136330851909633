define("discourse/plugins/discourse-badge-search/discourse/components/badge-avatar", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BadgeAvatar extends _component.default {
    init() {
      super.init(...arguments);
    }
    tagName = "a";
    attributeBindings = ["user.username:data-user-card", "user.path:href"];
  }
  _exports.default = BadgeAvatar;
});