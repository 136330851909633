define("discourse/plugins/discourse-badge-search/discourse/controllers/badge-search-index", ["exports", "@ember/controller", "discourse-common/utils/decorators"], function (_exports, _controller, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function badgeKey(badge) {
    let pos = badge.get("badge_grouping.position");
    let type = badge.get("badge_type_id");
    let name = badge.get("name");
    return ("000" + pos).slice(-4) + (10 - type) + name;
  }
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    messageHidden: true,
    message: "Select at least one badge to display candidates",
    badgeGroups(model) {
      window.console.log("doing badgeGroups in controller with ", model);
      let sorted = model.sort((a, b) => badgeKey(a).localeCompare(badgeKey(b)));
      let grouped = [];
      let group = [];
      let groupId;
      sorted.forEach(function (badge) {
        if (groupId !== badge.badge_grouping_id) {
          if (group && group.length > 0) {
            grouped.push({
              badges: group,
              badgeGrouping: group[0].badge_grouping
            });
          }
          group = [];
          groupId = badge.badge_grouping_id;
        }
        // badge.has_badge = true;
        group.push(badge);
      });
      if (group && group.length > 0) {
        grouped.push({
          badges: group,
          badgeGrouping: group[0].badge_grouping
        });
      }
      return grouped;
    },
    actions: {
      getMatchingUsers() {
        const selectedBadges = this.groups.filter(badge => {
          return badge.isSelected;
        });
        if (selectedBadges.length < 1) {
          this.set("messageHidden", false);
          return;
        }
        this.set("messageHiden", true);
        window.console.log("getMatchingUsers badges", selectedBadges);
        const ids = selectedBadges.map(badge => badge.id).join("+");
        const myParams = {
          b: ids
        };
        window.console.log(`myParams:`, myParams);
        this.transitionToRoute(`badge-users`, {
          queryParams: myParams
        });
      }
    }
  }, [["method", "badgeGroups", [(0, _decorators.default)("groups")]]]));
});