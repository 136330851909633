define("discourse/plugins/discourse-badge-search/discourse/badge-search-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("badge-search", function () {
      this.route("show", {
        path: "/badge-search"
      });
    });
    this.route("badge-users", function () {
      this.route("show", {
        path: "/badge-users"
      });
    });
  }
});