define("discourse/plugins/discourse-badge-search/discourse/routes/badge-search-index", ["exports", "discourse/routes/discourse", "discourse/models/badge", "discourse/lib/preload-store"], function (_exports, _discourse, _badge, _preloadStore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    needs: ["b"],
    model() {
      // const b = this.get("b");
      window.console.log("doing the model", this);
      if (_preloadStore.default.get("badges")) {
        window.console.log("got preload");
        return _preloadStore.default.getAndRemove("badges").then(json => _badge.default.createFromJson(json));
      } else {
        return _badge.default.findAll({
          onlyListable: true
        });
      }
    },
    setupController(controller, model) {
      window.console.log("seting up ", controller, model);
      controller.set("groups", model);
    }
  });
});